<button
  nz-button
  nzType="primary"
  class="btn-blue"
  nz-dropdown
  [nzDropdownMenu]="menuFormat"
  nzPlacement="bottomRight"
  nzTrigger="click"
  [ngStyle]="styles"
>
  Descargar
</button>

<nz-dropdown-menu #menuFormat="nzDropdownMenu">
  <ul nz-menu class="menu_dropdown">
    <div nz-row class="menu_title">Formato de descarga</div>
    @for (item of menuOptions; track $index) {
    <li nz-menu-item class="menu_item" (click)="onSelectOption(item.key)">
      {{ item.label }}
    </li>
    }
  </ul>
</nz-dropdown-menu>
