<nz-space nzDirection="vertical" [nzSize]="8">
  <div *nzSpaceItem nz-row nzJustify="center" nzAlign="middle">
    <i class="fa-solid fa-upload"></i>
  </div>

  <div *nzSpaceItem nz-row nzJustify="center" nzAlign="middle">
    <span class="title-alerts">Subiendo archivo</span>
  </div>
  <div *nzSpaceItem nz-row nzJustify="start" nzAlign="middle">
    {{ fileName }}
  </div>
</nz-space>
<nz-progress [nzPercent]="percent" [nzShowInfo]="false"></nz-progress>
