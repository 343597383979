/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GetUserSubscriptionModel } from '@carwash-project/modules/data-access/client-plans';
import { UTILS } from '@carwash-project/modules/utils';
import { UiIconAlertsComponent } from '../ui-icon-alerts/ui-icon-alerts.component';
import { UI_NGZORRO } from '../ui-ngzorro';

const ENABLED_STATES = [
  'Activa',
  'Suspendida',
  'Cancelacion programada',
  'Inactiva',
];

@Component({
  selector: 'ui-alerts-subs',
  standalone: true,
  imports: [UI_NGZORRO, UTILS, UiIconAlertsComponent],
  templateUrl: './ui-alerts-subs.component.html',
  styleUrl: './ui-alerts-subs.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAlertsSubsComponent {
  private _model: GetUserSubscriptionModel.UserSubs[] = [];
  @Input({ required: true })
  public isVisible!: boolean;

  @Input({ required: true })
  public set model(data: GetUserSubscriptionModel.UserSubs[]) {
    this._model = this.sortByStatus(data);
  }

  public get model() {
    return this._model;
  }

  @Output()
  public isVisibleChange = new EventEmitter<boolean>();

  public sortByStatus(data: GetUserSubscriptionModel.UserSubs[]) {
    return data
      .filter((item) => ENABLED_STATES.includes(item.status))
      .sort(
        (a, b) =>
          ENABLED_STATES.indexOf(a.status) - ENABLED_STATES.indexOf(b.status)
      );
  }
}
