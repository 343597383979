import { StorageStateModel } from './storage.state';

export namespace StorageActions {
  export class Save {
    static readonly type = '[Storage] save local storage';
    constructor(public payload: StorageStateModel) {}
  }

  export class Update {
    static readonly type = '[Storage] update local storage';
    constructor(public payload:Partial<StorageStateModel>) {}
  }
}
