/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ProgressBarService } from '@carwash-project/modules/ui';
import { HttpEventUploadFileModel } from '../models/interfaces/http/http.interfaces';

export const getPercentFn = (
  event: HttpEvent<any>,
  file: File
): HttpEventUploadFileModel => {
  switch (event.type) {
    case HttpEventType.UploadProgress: {
      const percentDone = event.total
        ? Math.round((100 * event.loaded) / event.total)
        : 0;
      return {
        percent: percentDone,
        fileName: file.name,
        EventType: event.type,
      };
    }

    case HttpEventType.Response: {
      return { response: event.body, EventType: event.type };
    }

    default: {
      return { EventType: event.type };
    }
  }
};

export const progressBarControlFn = (
  event: HttpEventUploadFileModel,
  progressBarService: ProgressBarService
) => {
  event.EventType === HttpEventType.UploadProgress
    ? progressBarService.onCreateProgressBar(event)
    : event.EventType === HttpEventType.Response
    ? progressBarService.onCompleteProgress()
    : console.log('OTRO EVENTO');
};

export const eventResponseFn = (event: HttpEventUploadFileModel) =>
  event.EventType === HttpEventType.Response ? event.response : event;
