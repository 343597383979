import { Injectable, computed, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private _searchQuery = signal<string | null>(null);
  public searchQuery = computed(()=>this._searchQuery());


  public updateQuery(query:string | null){
    this._searchQuery.set(query);
  }
}
