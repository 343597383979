@switch (type) { 
    @case ('check') {
        <div class="icon-custom-check">
          <div class="icon-custom-check-success">
            <div class="icon-custom-check-success-tip"></div>
            <div class="icon-custom-check-success-long"></div>
            <div class="icon-custom-check-success-placeholder"></div>
            <div class="icon-custom-check-success-fix"></div>
          </div>
        </div>
    } 
    @case ('error') {
        <div class="icon-custom-error">
          <div class="icon-custom-error-error">
            <div class="icon-custom-error-error-x">
              <div class="icon-custom-error-error-left"></div>
              <div class="icon-custom-error-error-right"></div>
            </div>
            <div class="icon-custom-error-error-placeholder"></div>
            <div class="icon-custom-error-error-fix"></div>
          </div>
        </div>
    } 
    @case ('question') {
        <div class="custom-icon custom-question custom-icon-show">
          <div class="custom-icon-content">?</div>
        </div>
    } 
    @case ('loading') {
        <div class="custom-icon custom-loading custom-icon-show">
          <div class="custom-icon-content">
            <span nz-icon nzType="history" nzTheme="outline"></span>
          </div>
        </div>
    } 
    @case ('warning') {
        <div class="icon-custom-warning">
          <div class="icon-custom-warning-warning">
            <div class="icon-custom-warning-warning-body"></div>
            <div class="icon-custom-warning-warning-dot"></div>
          </div>
        </div>
    } 
}
    