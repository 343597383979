import { inject, Injectable } from '@angular/core';
import {
    DefaultError,
    injectQuery,
    QueryKey,
    UndefinedInitialDataOptions
} from '@tanstack/angular-query-experimental';
import { GlobalErrorHandler } from '../../error-handler/error-handler';

@Injectable({
  providedIn: 'root',
})
export class QueryClientService {
  private errorHandler = inject(GlobalErrorHandler);

  public queryFactory = <
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
  >(
    optionsFn: () => UndefinedInitialDataOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryKey
    >
  ) => {
    return injectQuery(optionsFn);
  };
}
