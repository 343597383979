import { HttpUploadFileModel } from '../models/interfaces/http/http.interfaces';

export const fileToFormDataFn = (body: HttpUploadFileModel) => {
  const formData: FormData = new FormData();

  if (body.additionalParams) {
    const params = body.additionalParams;
    const keys = Object.keys(body.additionalParams);
    keys.forEach((key) => {
      const value: string | Blob = params[key as keyof typeof params];
      formData.append(key, value);
    });
  }

  formData.append(body.name, body.file);

  return formData
};
