import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreditCardModel } from '../../models';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
  selector: 'ui-credit-card',
  standalone: true,
  templateUrl: './ui-credit-card.component.html',
  styleUrl: './ui-credit-card.component.less',
  imports:[UI_NGZORRO],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCreditCardComponent {
  @Input()
  public model:CreditCardModel | null = null;

  public getExpirationDate(date:string | undefined){
    if(!date) return '';
    const [month,year] = date.split('/');

    return `${month}/${year[year.length-2]}${year[year.length-1]}`

  }
}
