<ul nz-menu nzTheme="dark" nzMode="inline" style="width: 100%">
  <ng-container
    *ngTemplateOutlet="menuTpl; context: { $implicit: menuTemplate }"
  ></ng-container>
  <ng-template #menuTpl let-menus>
    @for (menu of menus; track $index) { 
      @if (!menu.children && (menu.visible ??true)) {
        <li
          nz-menu-item
          [nzDisabled]="menu.disabled"
          [nzSelected]="menu.selected"
          [nzPaddingLeft]="menu.level * 24"
          [routerLink]="menu.router ? [menu.router] : []"
          routerLinkActive
          #rla="routerLinkActive"
          [nzSelected]="rla.isActive && menu.router"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <span [class]="menu.icon" style="margin-right: 15px"></span>
          <span [innerHtml]="menu.label" style="line-height: 15px"></span>
        </li>
    }@else if (menu.children && (menu.visible ?? true)) {
        <li
          nz-submenu
          [nzPaddingLeft]="menu.level * 24"
          [nzOpen]="menu.open"
          [nzTitle]="title"
          [nzDisabled]="menu.disabled"
        >
          <ng-template #title>
            <span [class]="menu.icon" style="margin-right: 15px"></span>
            <span [innerHtml]="menu.label" style="line-height: 15px"></span>
          </ng-template>
          <ul>
            <ng-container
              *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"
            ></ng-container>
          </ul>
        </li>
    } }
  </ng-template>
</ul>
