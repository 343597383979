export const numbersPattern: string = '^[0-9,$]*$';
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
export const passwordPattern: RegExp =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const namesPattern: RegExp =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,48}/;
export const EasyRegexPattern: RegExp =
  /^(?=.*[A-Za-z])(?=.*d)(?=.*[$@$!%*#?&])[A-Za-zd$@$!%*#?&]{8,}$/;
export const mediumRegexPattern: RegExp =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
export const strongRegexPattern: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

export const hasNumberRegexPattern: RegExp = /\d/;
export const hasCapitalCaseRegexPattern: RegExp = /[A-Z]/;
export const hasSmallCaseRegexPattern: RegExp = /[a-z]/;
export const hasSpecialSymbolsRegexPattern: RegExp = /^(?=.*[!@#$%^&*])/;
export const minLengthRegexPattern: RegExp = /(?=.{8,})/;
export const nitRegexPattern: RegExp = /^[0-9]+(-[0-9kK])$/;
export const phoneRegex: RegExp = /^([+]502)?[2-9]\d{7}$/;

export const GUATEMALA_PHONE_REGEX = /^([+]502)?[2-9]\d{7}$/;
export const DATE_REGEX =
  /^(?:\d{4})\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
export const TIME_REGEX_24H = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const TIME_REGEX_12H = /^(0[1-9]|1[0-2]):[0-5][0-9] [APap][mM]$/;

export const GUATEMALA_PLATE = /^([+]502)?[2-9]\d{7}$/;


export const ROLE_NAME = {
  CLIENT: 'Cliente',
  EMPLOYEE: 'Empleado',
  SUPERADMIN: 'Super administrador',
  OPERATOR: 'Operador',
  PAYSTATION: 'PayStation',
};
