import * as CryptoJS from 'crypto-js';

export const encryptFn = (data: string, key: string): string => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

export const decryptFn = <T>(valueEncrypt: string, key: string): T | null => {
  const valueDecrypt = CryptoJS.AES.decrypt(valueEncrypt, key).toString(
    CryptoJS.enc.Utf8
  );
  if (!valueDecrypt) {
    return null;
  }
  return JSON.parse(valueDecrypt) as T;
};
