import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";


@Injectable({
  providedIn: 'root'
})
export class LoggingErrorService {

  public logError(message: string, stack: Error | HttpErrorResponse | string){
    Sentry.captureException(stack);
    Sentry.captureMessage(message,'error');
  }
}
