<input
  nz-input
  type="text"
  [(ngModel)]="value"
  [mask]="mask()"
  [suffix]="suffix()"
  [prefix]="prefix()"
  thousandSeparator=","
  decimalMarker="."
  (ngModelChange)="onChangeValue($event)"
/>
<button
  nz-button
  nzType="primary"
  class="suffix"
  nz-dropdown
  [nzDropdownMenu]="menuFormat"
  nzPlacement="bottomRight"
  nzTrigger="click"
>
  <span nz-icon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(-0.401 -0.401)">
        <g id="invisible_box" data-name="invisible box">
          <rect
            id="Rectángulo_2139"
            data-name="Rectángulo 2139"
            width="21"
            height="21"
            transform="translate(0.401 0.401)"
            fill="none"
          />
        </g>
        <g id="Icons" transform="translate(1.363 0.908)">
          <g id="Grupo_7027" data-name="Grupo 7027">
            <path
              id="Trazado_1642"
              data-name="Trazado 1642"
              d="M20.8,26.406a2.089,2.089,0,0,0-1.817-.5l-4.5.772a2.135,2.135,0,0,0-.636-1.5L10.631,22H3.908a.908.908,0,0,0,0,1.817h6l2.68,2.68a.363.363,0,0,1,0,.5.409.409,0,0,1-.545,0l-1.59-1.59a.915.915,0,1,0-1.272,1.317l1.59,1.544a2.044,2.044,0,0,0,1.544.636,2.589,2.589,0,0,0,.818-.136h0l6.177-1.09a.454.454,0,0,1,.363.091.5.5,0,0,1,.136.318.454.454,0,0,1-.363.454l-8.448,1.544L6.043,26.042H3.908a.908.908,0,1,0,0,1.817h1.5l5.042,4.133,9.311-1.681a2.226,2.226,0,0,0,1.862-2.226A2.089,2.089,0,0,0,20.8,26.406Z"
              transform="translate(-3 -12.916)"
              fill="#fff"
            />
            <path
              id="Trazado_1643"
              data-name="Trazado 1643"
              d="M30.3,10.221h0a3.043,3.043,0,0,1-1.862-.59A.908.908,0,0,0,27.17,9.9a.818.818,0,0,0,.136,1.181,4.951,4.951,0,0,0,1.953.818v1a.908.908,0,1,0,1.817,0v-1a2.862,2.862,0,0,0,1.363-.59,2.226,2.226,0,0,0,.908-1.817h0c0-1.681-1.544-2.226-2.862-2.5s-1.59-.59-1.59-.818.091-.273.227-.409a1.544,1.544,0,0,1,.818-.182,2.862,2.862,0,0,1,1.5.409.818.818,0,0,0,1.226-.227A.863.863,0,0,0,32.484,4.5a4.133,4.133,0,0,0-1.408-.545V2.908a.908.908,0,1,0-1.817,0v1A2.447,2.447,0,0,0,26.988,6.27c0,1.226,1.5,2.226,2.952,2.5s1.5.59,1.5.818S30.939,10.221,30.3,10.221Z"
              transform="translate(-16.087 -2)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</button>

<nz-dropdown-menu #menuFormat="nzDropdownMenu">
  <ul nz-menu class="menu_dropdown">
    <div nz-row class="menu_title">Tipo de valor</div>
    <li
      nz-menu-item
      class="menu_item"
      (click)="handleSelectOption('Porcentual')"
    >
      Porcentual
    </li>
    <li nz-menu-item class="menu_item" (click)="handleSelectOption('Fijo')">
      Fijo
    </li>
  </ul>
</nz-dropdown-menu>
