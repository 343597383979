import { Injectable, inject } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class UiNotificationsService {
  private nzNotifications = inject(NzNotificationService);

  public success(
    title: string,
    message: string,
    key: string | undefined = undefined
  ) {
    this.nzNotifications.success(title, message, {
      nzKey: key,
      nzPlacement: 'topRight',
      nzDuration: 5000,
      nzClass: 'ntf_success',
    });
  }

  public warning(
    title: string,
    message: string,
    key: string | undefined = undefined
  ) {
    this.nzNotifications.warning(title, message, {
      nzKey: key,
      nzPlacement: 'topRight',
      nzDuration: 5000,
      nzClass: 'ntf_warn',
    });
  }

  public error(
    title: string,
    message: string,
    key: string | undefined = undefined
  ) {
    this.nzNotifications.error(title, message, {
      nzKey: key,
      nzPlacement: 'topRight',
      nzDuration: 5000,
      nzClass: 'ntf_error',
    });
  }

  public info(
    title: string,
    message: string,
    key: string | undefined = undefined
  ) {
    this.nzNotifications.info(title, message, {
      nzKey: key,
      nzPlacement: 'topRight',
      nzDuration: 5000,
      nzClass: 'ntf_info',
    });
  }

  public remove(key: string) {
    this.nzNotifications.remove(key);
  }
}
