import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  effect,
  signal,
} from '@angular/core';
import { UTILS } from '@carwash-project/modules/utils';
import { Subject, interval, takeUntil } from 'rxjs';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
  selector: 'ui-countdown',
  standalone: true,
  imports: [CommonModule, UTILS, UI_NGZORRO],
  template: `
    <span class="countDown"
      ><i class="fa-solid fa-clock" style="margin-right: 4px;"></i
      >{{ countDown() }} min</span
    >
  `,
  styles: `
    .countDown{
      font:normal normal 500 12px Montserrat-SemiBold;
      color:#CF1322
    }

  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCountdownComponent implements OnDestroy {
  public counter$ = new Subject<void>();
  public countDown = signal<number>(0);

  @Output()
  public clearEvent = new EventEmitter<void>();

  constructor() {
    effect(
      () => {
        if (this.countDown() <= 0) {
          this.clearEvent.emit();
          this.counter$.next();
          this.counter$.complete();
        }
      },
      { allowSignalWrites: true }
    );
  }

  @Input()
  public set waitTime(time: number | null) {
    if (!time || time===0) return;

    this.countDown.set(time);
    
    interval(60000)
      .pipe(takeUntil(this.counter$))
      .subscribe({
        next: () => {
          this.countDown.update((time) => time - 1);
        },
        complete: () => {
          console.log('COMPLETE COUNTER');
        },
      });
  }

  public ngOnDestroy(): void {
    this.counter$.next();
    this.counter$.complete();
  }
}
