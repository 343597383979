import { Injectable, computed, inject, signal } from '@angular/core';
import { AlertModel } from '../models/interfaces/modal.interfaces';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UiAlertsService {
  private router = inject(Router);
  private alertConfig = signal<AlertModel | null>(null);
  public getAlertConfig = computed(() => this.alertConfig());

  public create(alertConfig: AlertModel) {
    this.alertConfig.set(alertConfig);
  }

  public success(
    message: string,
    title: string = 'Listo',
    link: string | null = null
  ) {
    this.create({
      icon: 'check',
      title: title,
      message: message,
      buttons: [
        {
          type: 'primary',
          label: 'Genial',
          onClick: (modelRef: NzModalRef) => {
            if (link) {
              this.router.navigate([link]);
            }
            modelRef.destroy();
          },
        },
      ],
    });
  }

  public warning(
    message: string,
    title: string = 'Advertencia',
    link: string | null = null
  ) {
    this.create({
      icon: 'warning',
      title: title,
      message: message,
      buttons: [
        {
          type: 'primary',
          label: 'Entendido',
          onClick: (modelRef: NzModalRef) => {
            if (link) {
              this.router.navigate([link]);
            }
            modelRef.destroy();
          },
        },
      ],
    });
  }

  public error(
    message: string,
    title: string = 'Error',
    link: string | null = null
  ) {
    this.create({
      icon: 'error',
      title: title,
      message: message,
      buttons: [
        {
          type: 'primary',
          label: 'Entendido',
          onClick: (modelRef: NzModalRef) => {
            if (link) {
              this.router.navigate([link]);
            }
            modelRef.destroy();
          },
        },
      ],
    });
  }
}
