<nz-table
  #Table
  class="dynamic-table"
  [nzData]="displayData"
  [nzSize]="'middle'"
  [nzBordered]="true"
  [nzTitle]="HeaderSumary"
  [nzTableLayout]="'fixed'"
  [nzShowPagination]="configs?.pagination ?? true"
  [nzTotal]="dataSet?.count ?? 0"
  [nzFrontPagination]="!configs?.loadOnDemand"
  [nzPageIndex]="dataSet?.pageIndex!"
  [nzPageSize]="dataSet?.pageSize!"
  [nzLoading]="loading"
  [nzShowSizeChanger]="configs?.simplePagination ?? false"
  [nzHideOnSinglePage]="false"
  (nzPageIndexChange)="handelPageIndexChange($event)"
  (nzCurrentPageDataChange)="handleCurrentPageDataChange($event)"
  (nzQueryParams)="handleQueryParams($event)"
>
  <thead>
    <tr>
      @if (configs?.checkbox && configs?.canMultiSelection) {
      <th
        nzWidth="60px"
        [(nzChecked)]="allChecked"
        [nzLeft]="configs?.isFixedLeftCheck ?? false"
        [nzIndeterminate]="isIndeterminate"
        (nzCheckedChange)="onCheckAll($event)"
      ></th>
      }@else if(configs?.checkbox){
      <th nzWidth="60px"></th>
      } @for (col of columnsDisplay; track $index) {
      <th
        [nzWidth]="col?.width ?? null"
        [nzLeft]="(col.isFixedLeft ?? false) && !isSmallScream"
        [nzRight]="(col.isFixedRight ?? false) && !isSmallScream"
        [nzColumnKey]="col.field"
        [nzShowSort]="col.showSort??false"
        [nzSortFn]="col.sortFn ?? null"
        (nzSortOrderChange)="onSortChange($event, col)"
      >
        {{ col.displayName }}
      </th>
      } @if (!isSmallScream && configs?.enabledActionColumn) {
      <th
        [nzRight]="(configs?.actionColumnFixed ?? false) && !isSmallScream"
        [nzWidth]="configs?.actionColumnWidth ?? null"
      >
        {{ configs?.actionColumnHeader ?? 'Acciones' }}
      </th>
      } @if (isSmallScream) {
      <th nzWidth="60px"></th>
      }
    </tr>
  </thead>
  <tbody>
    @for (row of Table.data; track $index) {
    <tr>
      @if (configs?.checkbox) {
      <td
        [nzLeft]="configs?.isFixedLeftCheck ?? false"
        [(nzChecked)]="row.checked"
        [nzShowCheckbox]="configs?.checkbox"
        (nzCheckedChange)="refreshStatus(row)"
      ></td>
      } @for (col of columnsDisplay; track $index) {
      <td
        [nzLeft]="(col.isFixedLeft ?? false) && !isSmallScream"
        [nzRight]="(col.isFixedRight ?? false) && !isSmallScream"
        nzBreakWord
        class="td-content"
      >
        <ng-container
          [ngTemplateOutlet]="rows"
          [ngTemplateOutletContext]="{
            data: row,
            column: col,
            isColmunHide: false
          }"
        ></ng-container>
      </td>
      } @if (!isSmallScream && configs?.enabledActionColumn) {
      <td [nzRight]="(configs?.actionColumnFixed ?? false) && !isSmallScream">
        <ng-container
          [ngTemplateOutlet]="actions"
          [ngTemplateOutletContext]="{ data: row }"
        ></ng-container>
      </td>
      } @if (isSmallScream) {
      <td [(nzExpand)]="row.expand"></td>
      }
    </tr>

    @if (isSmallScream) {
    <tr [nzExpand]="row.expand">
      @for (col of columnsHide; track $index) {
      <ng-container
        [ngTemplateOutlet]="rows"
        [ngTemplateOutletContext]="{
          data: row,
          column: col,
          isColmunHide: true
        }"
      ></ng-container>
      }
      <ng-container
        [ngTemplateOutlet]="actions"
        [ngTemplateOutletContext]="{ data: row }"
      ></ng-container>
    </tr>

    } }
  </tbody>
</nz-table>

<ng-template #HeaderSumary>
  <div nz-row nzJustify="space-between" nzAlign="middle">
    @if (configs?.headerTitle) {
    <span class="header-title">Servicios</span>
    }

    <span class="header-summary"
      >Mostrando: {{ currentPageSize }} de {{ dataSet?.count }} entradas</span
    >
    <ng-container [ngTemplateOutlet]="additionalHeader"></ng-container>
  </div>
</ng-template>

<ng-template #rows let-row="data" let-col="column" let-show="isColmunHide">
  <div class="row" [ngClass]="{ 'row-responsive': show }">
    @if (show) {
    <span class="colname-responsive">{{ col.displayName }}: </span>
    } @switch (col.type) { @case ('custom') {
    <ng-container
      [ngTemplateOutlet]="customCell"
      [ngTemplateOutletContext]="{ $implicit: row, field:col.field }"
    ></ng-container>
    } @case ('link') {
    <span class="span-link">
      <a (click)="handleClickLink(row)" class="span-link__data">{{
        row[col.field]
      }}</a>
    </span>
    } @case ('currency') {
    {{ row[col.field] ?( row[col.field] | currencyQ):'N/A' }}
    } @case ('tag') {
    <nz-tag [nzColor]="setColorTag(row[col.field], col.tagCondition!)">
      {{ row[col.field] }}</nz-tag
    >
    } @case ('switch') {
    <nz-switch
      nzSize="small"
      [ngModel]="row['rowStatus'] ?? false"
      [nzCheckedChildren]="col.switchLabel?.labelOn ?? 'On'"
      [nzUnCheckedChildren]="col.switchLabel?.labelOff ?? 'Off'"
      (ngModelChange)="handleSwitch($event, row)"
      [nzDisabled]="col.disabled ? true : false"
    >
    </nz-switch>
    } @default {
    {{ row[col.field]??'N/A' }}
    } }
  </div>
</ng-template>
