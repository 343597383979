import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { CommonModule } from '@angular/common';
import { MenuDropdownModel } from '../../models';

@Component({
  selector: 'ui-data-download',
  templateUrl: './ui-data-download.component.html',
  styleUrl: './ui-data-download.component.less',
  standalone: true,
  imports: [UI_NGZORRO, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDataDownloadComponent {
  @Input()
  public styles?: { [key: string]: string };

  @Input()
  public menuOptions: MenuDropdownModel[] = [
    {
      key: 'PDF',
      label: 'PDF',
    },
    {
      key: 'XLSX',
      label: 'EXCEL',
    },
  ];

  @Output()
  public selectOptionEvent = new EventEmitter<string>()

  public onSelectOption(key: string) {
    this.selectOptionEvent.emit(key);
  }
}
