/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoggingErrorService } from './../services/errors/logging-error-service.service';
import { ErrorService } from './../services/errors/error.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import {
  UiAlertsService,
  UiNotificationsService,
} from '@carwash-project/modules/ui';
import { ErrorMessageModel } from '../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler extends ErrorHandler {
  public override handleError(error: Error | ErrorMessageModel): void {
    const alertService = this.injector.get(UiAlertsService);
    const uiNotifications = this.injector.get(UiNotificationsService);
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingErrorService);

    let message: string;
    let title: string;
    let stackTrace: Error | HttpErrorResponse | string;

    if (error instanceof Error) {
      message = errorService.getClientMessage(error).content;
      title = errorService.getClientMessage(error).title;
      stackTrace = errorService.getClientStack(error);
    } else {
      message = errorService.getServerMessage(error).content;
      title = errorService.getServerMessage(error).title;
      stackTrace = errorService.getServerStack(error);

      if (
        error.stack.status === 401 &&
        error.stack.error &&
        error.stack.error.code === 'expired'
      ) {
        uiNotifications.error(
          'La sesión caducó',
          'Vuelva a iniciar sesión.',
          'error_401'
        );
      } else {
        alertService.error(message, title);
      }
    }

    logger.logError(message, stackTrace);

    if (!errorService.isProd) {
      console.error(error);
    }
  }

  constructor(private injector: Injector) {
    super();
  }
}
