export enum PROFILES {
  'Cliente' = 1,
  'Super administrador' = 3,
  'Operador' = 4,
  'PayStation' = 5,
  'Cliente whitelist' = 18,
  'Gerente de tienda' = 17,
  'Contabilidad' = 19,
  'Socio' = 20,
  'Vendedor' = 15,
  'Asistente de gerente' = 16,
  'Mercadeo' = 22,
}

export const MODULES_ROUTER = {
  Dashboard: ['main', 'dashboard'],
  Reportería: ['main', 'reports', 'list-sales'],
  Inventario: ['main', 'inventory'],
  Empleados: ['main', 'employee'],
  Anuncios: ['main', 'adverts'],
  Clientes: ['main', 'customers'],
  'Promociones/Giftcards': ['main', 'promotions'],
  Encuestas: ['main', 'surveys'],
  Permisos: ['main'],
};

export enum MODULES_CODE {
  'CLIENT_001'= 'Dashboard-client',
  'ADMIN_001' = 'Dashboard',
  'ADMIN_002' = 'Reportería',
  'ADMIN_003' = 'Inventario',
  'ADMIN_004' = 'Empleados',
  'ADMIN_005' = 'Anuncios',
  'ADMIN_006' = 'Clientes',
  'ADMIN_007' = 'Promociones/Giftcards',
  'ADMIN_008' = 'Encuestas',
  'ADMIN_009' = 'Permisos',
}
