import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges
} from '@angular/core';

@Directive({
  selector: '[uiColorHelpText]',
  standalone:true
})
export class ColorHelpTextDirective implements OnChanges {
  
  @Input() 
  public validationError: boolean;
  
  @HostBinding('class') 
  public elementClass;

  constructor(private el: ElementRef) {
    this.validationError = false;
    this.elementClass = this.el.nativeElement.className;
  }

  public ngOnChanges(): void {
    if (this.validationError) {
      this.elementClass = 'text-muted';
    } else {
      this.elementClass = 'text-success';
    }
  }
}
