/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
  effect,
  inject,
} from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { UTILS } from '@carwash-project/modules/utils';
import { UiIconAlertsComponent } from '../ui-icon-alerts/ui-icon-alerts.component';
import { AlertModel } from '../../models/interfaces/modal.interfaces';
import { UiAlertsService } from '../../services/ui-alerts.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ui-alerts',
  standalone: true,
  imports: [UI_NGZORRO, UTILS, UiIconAlertsComponent],
  templateUrl: './ui-alerts.component.html',
  styleUrl: './ui-alerts.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAlertsComponent {
  private readonly modal = inject(NzModalService);
  public uiAlertsService = inject(UiAlertsService);
  public alertConfig: AlertModel | null = null;

  @ViewChild('customIcon')
  tplIcon!: TemplateRef<void>;

  @ViewChild('modalContent')
  tplContent!: TemplateRef<void>;

  @ViewChild('modalFooter')
  tplFooter!: TemplateRef<any>;

  constructor() {
    effect(() => {
      if (this.uiAlertsService.getAlertConfig()) {
        this.alertConfig = this.uiAlertsService.getAlertConfig() as AlertModel;
        this.createTplModal(this.tplIcon, this.tplContent, this.tplFooter);
      }
    });
  }

  public createTplModal(
    tplIcon: TemplateRef<void>,
    tplContent: TemplateRef<void>,
    tplFooter: TemplateRef<any>
  ) {
    this.modal.create({
      nzClassName: 'alert-custom-modal',
      nzTitle: undefined,
      nzCloseIcon: tplIcon,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzCentered: true,
      nzClosable: true,
      nzWidth:'450px'
    })
  }
}
