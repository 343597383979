import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MainMenuModel } from '../../models';
import { UI_NGZORRO } from '../ui-ngzorro';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'ui-inline-menu',
    templateUrl: './ui-inline-menu.component.html',
    styleUrl: './ui-inline-menu.component.less',
    standalone: true,
    imports: [
      UI_NGZORRO,
      RouterModule,
      CommonModule
    ],
    changeDetection:ChangeDetectionStrategy.OnPush
})
export class UiInlineMenuComponent {
  @Input({ required: true }) 
  public menuTemplate: MainMenuModel[] = [];
}
