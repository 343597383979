import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { CommonModule } from '@angular/common';
import { Dropdown } from '../../models';


@Component({
    selector: 'ui-dropdown',
    templateUrl: './ui-dropdown.component.html',
    styleUrl: './ui-dropdown.component.less',
    standalone: true,
    imports: [
      UI_NGZORRO,
      CommonModule
    ],
    changeDetection:ChangeDetectionStrategy.OnPush
})
export class UiDropdownComponent {
  public isVisible:boolean=false;

  @Input({ required: true })
  public model!: Dropdown;

  public handleClick(key:string){
    this.model.command(key)
  }
}
