/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpContext, HttpParams } from '@angular/common/http';
import {
  HttpHeadersModel,
  HttpOptionsModel,
  HttpParamsModel,
  HttpQueryModel,
} from '../../interfaces/http/http.interfaces';

export class HttpOptions implements HttpOptionsModel {
  constructor(
    private token: string | null,
    public headers: HttpHeadersModel | undefined = undefined,
    public context: HttpContext | undefined = undefined,
    public observe: 'body' | undefined = undefined,
    public params: HttpParamsModel | undefined = undefined,
    public reportProgress: boolean | undefined = undefined,
    public responseType: 'json' | undefined = undefined,
    public withCredentials: boolean | undefined = undefined,
    public body: any | null = undefined
  ) {
    this.setHeaders();
    this.setTokenToHeader();
  }

  private setTokenToHeader() {
    if (this.token) {
      this.headers = {
        ...this.headers,
        authorization: `Bearer ${this.token}`,
      };
    }
  }

  public setHeaders(header?: HttpHeadersModel) {
    this.headers = {
      Accept: 'charset=utf-8',
    };

    if (header) {
      this.headers = {
        ...this.headers,
        ...header,
      };
    }
  }

  public setQueriesToHttpParams<K = HttpQueryModel | null>(fromObject: K) {
    if (!fromObject) return;
    const newParams = new HttpParams({
      fromObject,
    });

    this.params = newParams;
  }

  public setOption<T extends keyof HttpOptionsModel>(
    optionKey: T,
    optionValue: HttpOptionsModel[T] | null
  ): this {
    if (!optionValue) return this;

    this[optionKey] = optionValue;

    return this;
  }
}
