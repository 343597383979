import { Injectable, inject } from '@angular/core';
import { ORG_ID } from '../../configs';

@Injectable({
  providedIn: 'root',
})
export class SessionIDService {
  private readonly orgID = inject(ORG_ID);

  public setSessionID(sessionID: string) {
    this.loadScript({ id: 'tag-ses-01', sessionID })
      .then((data) => {
        console.log('script loaded');
      })
      .catch((error) => console.log(error));

    this.loadNonScript({ id: 'tag-ses-02', sessionID });
  }

  public loadNonScript({ id, sessionID }: { id: string; sessionID: string }) {
    if (id && document.querySelector(`#${id}`)) {
      this.removeScript(id);
    }
    const body = document.body;
    const nonScript = document.createElement('noscript');
    nonScript.id = id;
    nonScript.textContent = `
      <iframe      
        style="
          width: 100px;
          height: 100px;
          border: 0;
          position: absolute;
          top: -5000px;
        "
      src="https://h.online-metrix.net/fp/tags?org_id=${this.orgID} &session_id=visanetgt_rocketcarwash${sessionID}"></iframe>
    `;

    body.appendChild(nonScript);
  }

  public loadScript({ id, sessionID }: { id: string; sessionID: string }) {
    return new Promise((resolve) => {
      if (id && document.querySelector(`#${id}`)) {
        this.removeScript(id);
      }
      const head = document.head;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${this.orgID}&session_id= visanetgt_rocketcarwash${sessionID}`;
      script.id = id;
      script.onload = () => {
        resolve({ id, loaded: true, status: 'Loaded' });
      };

      script.onerror = () => {
        resolve({ id, loaded: false, status: 'Loaded' });
      };
      head.appendChild(script);
    });
  }

  public removeScript(id: string) {
    const element = document.querySelector(`#${id}`);
    if (element) {
      element.remove();
    }
  }
}
