<nz-layout>
  <ng-container [ngTemplateOutlet]="sider"></ng-container>
  <div
    *ngIf="enabledFloatButton"
    class="drawer-handle"
    [ngClass]="{ 'drawer-handle-collapsed': isCollapsed }"
  >
    <button
      nz-button
      nzSize="small"
      nzType="text"
      (click)="isCollapsed = !isCollapsed"
    >
      <span
        class="fa-solid"
        [ngClass]="{
          'fa-chevron-left': !isCollapsed,
          'fa-chevron-right': isCollapsed
        }"
      ></span>
    </button>
  </div>
  <nz-layout
    [ngClass]="{
        'right-layout': !isCollapsed && !isSmallScream,
        'right-layout-collapsed': isCollapsed  && !isSmallScream,
      }"
  >
    <ng-container [ngTemplateOutlet]="header"></ng-container>
    <nz-content (click)="handlerOpen()">
      <div class="inner-content">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </nz-content>
    <nz-footer>
      <span>CarWash © {{ year }}</span>
      <span>V.{{ appVersion }}</span>
    </nz-footer>
  </nz-layout>
</nz-layout>

<ng-template #header>
  <nz-header>
    <div class="header-title">
      <button
        nz-button
        nzSize="small"
        nzType="text"
        (click)="isCollapsed = !isCollapsed"
      >
        <span
          class="fa-solid fa-lg"
          [ngClass]="{
            'fa-bars': !isCollapsed,
            'fa-bars-staggered': isCollapsed
          }"
        ></span>
      </button>
      <div nz-space nzAlign="center" class="header-title">
        <nz-divider *nzSpaceItem nzType="vertical"></nz-divider>
        <span *nzSpaceItem>
          <ng-container *ngTemplateOutlet="tplSearch"></ng-container>
        </span>
      </div>
    </div>
  </nz-header>
</ng-template>

<ng-template #sider>
  <nz-sider
    nzCollapsible
    nzBreakpoint="lg"
    [(nzCollapsed)]="isCollapsed"
    [nzCollapsedWidth]="0"
    [nzTrigger]="null"
  >
    <div [classList]="configs?.sider?.boxHeaderClass">
      <div *ngFor="let item of configs?.sider?.logoUrls">
        <img [src]="item.url" [classList]="item.classList ?? []" />
      </div>
    </div>
    <div
      nz-row
      nzJustify="center"
      nzAlign="middle"
      style="text-align: center; margin-bottom: 12px"
    >
      <div nz-col nzSpan="24" style="margin-bottom: 15px">
        <nz-avatar
          nzIcon="user"
          [nzSrc]="user?.avatar ?? undefined"
          [nzSize]="70"
        ></nz-avatar>
      </div>
      <div nz-col nzSpan="24">
        <h4 class="name-profile">Hola, {{ user?.name }}</h4>
      </div>
      <div nz-col nzSpan="24" class="description-name">
        {{ user?.role?.name }}
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24">
        <ng-container *ngTemplateOutlet="tplMenu"></ng-container>
      </div>
    </div>
    <div nz-row nzJustify="start" class="box-logout">
      <div nz-col nzSpan="24" style="text-align: start;">
        <button
          nz-button
          nzType="text"
          style="color: rgba(255, 255, 255, 0.65); font-size: 12px"
          (click)="logoutInit()"
        >
          <i
            class="fa-solid fa-right-from-bracket"
            style="margin-right: 5px"
          ></i>
          Cerrar sesión
        </button>
      </div>
    </div>
  </nz-sider>
</ng-template>
