/* eslint-disable @typescript-eslint/no-explicit-any */
export class TableData<K = any> {
  public pageSize: number;
  public pageIndex: number;
  public count: number;
  public results;

  constructor(
    private dataList: K[],
    private totalCount: number = dataList.length,
    private pIndex: number = 1,
    private pSize: number = 5
  ) {
    this.pageIndex = pIndex;
    this.pageSize = pSize;
    this.count = totalCount;
    this.results = ([] as K[]).concat(dataList);
  }
}
