import { FormControl } from '@angular/forms';

export function validationInput(formControl: FormControl): boolean {
  return formControl.invalid && (formControl.touched || formControl.dirty);
}

export function getCardType(cardNumber: string) {
  const cleanedNumber = cardNumber.replace(/\D/g, '');
  if (/^4/.test(cleanedNumber)) {
    return 'VISA';
  } else if (/^5[1-5]/.test(cleanedNumber)) {
    return 'MASTERCARD';
  } else if (/^3[47]/.test(cleanedNumber)) {
    return 'AMEX';
  } else {
    return null;
  }
}


export function generateStrongPassword(length:number = 12):string {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numericChars = "0123456789";
  const specialChars = "!@#$%^&*()_-+=<>?";

  const allChars =
    uppercaseChars + lowercaseChars + numericChars + specialChars;

  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    password += allChars[randomIndex];
  }

  return password;
}
