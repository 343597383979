import { Injectable } from '@angular/core';
import { decryptFn, encryptFn } from '@carwash-project/modules/utils';
import { StorageEngine } from '@ngxs/storage-plugin';

@Injectable()
export class CustomStorageEngine implements StorageEngine {
  #keyEncrypt = 'CARWASH_DEV';

  getItem(key: string): string | null {
    let data = localStorage.getItem(key);
    if (data) {
      data = JSON.stringify(decryptFn(data, this.#keyEncrypt));
    }

    return data;
  }

  setItem(key: string, value: string): void {
    const encryptInfo = encryptFn(value, this.#keyEncrypt);
    localStorage.setItem(key, encryptInfo);
  }
}
