import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { UiProgressBarComponent } from '../components/ui-progress-bar/ui-progress-bar.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  private modal  = inject(NzModalService);
  private cancel$ = new Subject<void>();
  private instance: UiProgressBarComponent | null = null;
  private modalRef!: NzModalRef<UiProgressBarComponent>;

  get cancelClick() {
    return this.cancel$.asObservable();
  }

  public onCreateProgressBar(data: {
    percent?: number;
    fileName?: string;
    EventType: HttpEventType;
  }) {
    if (this.instance === null) {
      this.modalRef = this.modal.create<UiProgressBarComponent>({
        nzTitle: undefined,
        nzClassName: "modal-progress-bar",
        nzCentered: true,
        nzWidth: 350,
        nzClosable: true,
        nzMaskClosable: false,
        nzContent: UiProgressBarComponent,
        nzFooter: [
          {
            label: "Cancelar",
            type: "default",
            onClick: () => {
              this.cancel$.next();
              this.cancel$.complete();
              this.modalRef?.destroy();
            },
          },
        ],
      });
      this.instance = this.modalRef.getContentComponent();
      this.instance.fileName = data.fileName ? data.fileName : "";
      this.instance.percent = data.percent ? data.percent : 0;

      this.modalRef.afterClose.subscribe(() => {
        console.log("Se cancelo la petición");
        this.instance = null;
      });
    } else {
      this.instance.percent = data.percent as number;
    }
  }

  public onCompleteProgress() {
    this.instance = null;
    setTimeout(() => {
      this.modal.closeAll();
    }, 1000);
  }
}
