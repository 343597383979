import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UI_NGZORRO } from '../ui-ngzorro';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ui-progress-bar',
  standalone: true,
  imports: [CommonModule, UI_NGZORRO],
  templateUrl: './ui-progress-bar.component.html',
  styleUrl: './ui-progress-bar.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiProgressBarComponent {
  readonly #modal = inject(NzModalRef);
  
  @Input() 
  fileName: string = '';

  @Input() 
  percent: number = 0;
}
