<div nz-row class="gift-card" [ngClass]="{'card-sm':small}">
  <div nz-col>
    <div nz-row style="margin: 0;">
      <div nz-col nzSpan="24" class="card-name">Gift card</div>
    </div>
    <div nz-row style="margin: 0;">
      <div nz-col nzSpan="18" class="card-value">{{ description }}</div>
    </div>
    <div nz-row style="margin: 0;">
        <div nz-col nzSpan="24" class="card-price">{{ price }}</div>
      </div>
  </div>
</div>
