import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'ui-header-back',
    template: `
    <div nz-row nzJustify="space-between" nzAlign="middle">
      <div nz-col nzXs="24" nzLg="12" class="page-subtitle">
        <div nz-row [nzGutter]="14" nzAlign="middle">
          <div nz-col>
            <a [routerLink]="[router]">
              <i class="fa-solid fa-arrow-left fa-xs"></i
            ></a>
          </div>
          <div nz-col class="label_back">Regresar</div>
        </div>
      </div>
    </div>
  `,
    styles: [
        `
      .label_back {
        font: normal normal bold 18px/30px Montserrat-SemiBold;
        color: #00aecc;
      }
    `,
    ],
    standalone: true,
    imports: [UI_NGZORRO,RouterModule],
    changeDetection:ChangeDetectionStrategy.OnPush
})
export class UiHeaderBackComponent {
  @Input({ required: true })
  public router!: string;
}
