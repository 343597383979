<button
  nz-button
  nzType="primary"
  class="btn-blue"
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzPlacement="bottomRight"
  nzTrigger="click"
  (nzVisibleChange)="isVisible = $event"
>
  {{ isVisible && model.activeLabel ? model.activeLabel : model.buttonLabel }}
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="menu_dropdown">
    <div nz-row class="menu_title">{{ model.menuTitle }}</div>
    @for (item of model.menu; track $index) {
    <li
      nz-menu-item
      class="menu_item"
      [ngClass]="{ active_item: item.key === model.selected }"
      (click)="handleClick(item.key)"
    >
      {{ item.label }}
    </li>
    }
  </ul>
</nz-dropdown-menu>
