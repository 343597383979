import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  signal,
} from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'ui-input-number',
  templateUrl: './ui-input-number.component.html',
  styleUrl: './ui-input-number.component.less',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UiInputNumberComponent),
      multi: true,
    },
  ],
  imports: [UI_NGZORRO, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiInputNumberComponent implements ControlValueAccessor, Validator {
  private onChanged!: (value: number) => void;
  private onTouch!: () => void;

  public isDisabled = false;
  public value = signal<number | null>(null);

  @Input()
  public step: number = 0.1;

  @Input()
  public placeholder: string = '';

  public onAdd() {
    if (this.value() === null) {
      this.value.set(0);
      return;
    }

    this.value.update((current) => Number((current! + this.step).toFixed(1)));
    if (this.onChanged) {
      this.onChanged(this.value()!);
    }
  }

  public onLess() {
    if (this.value() === null || this.value() === 0) {
      this.value.set(0)
      return;
    }
    this.value.update((current)=>Number((current! - this.step).toFixed(1))) ;

    if (this.onChanged) {
      this.onChanged(this.value()!);
    }
  }

  public onChangeValue(value: number) {
    this.value.set(value < 0 ? 0:value);
    this.onChanged(this.value() as number);
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return control.hasValidator(Validators.required) && !this.value
      ? { isRequired: true }
      : null;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.onChanged = fn;
  }

  public writeValue(value: number): void {
    const newValue = value;
    this.value.set(newValue);
  }

  public registerOnChange(fn: () => void): void {
    this.onChanged = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
