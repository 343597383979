<input
  type="number"
  nz-input
  [placeholder]="placeholder"
  class="custom_input_num"
  [ngModel]="value()"
  (ngModelChange)="onChangeValue($event)"
/>
<button nz-button nzType="primary" class="btn-add" (click)="onAdd()">
  <span nz-icon nzType="plus"></span>
</button>
<button nz-button nzType="primary" class="btn-less" (click)="onLess()">
  <span nz-icon nzType="minus"></span>
</button>
