import { Injectable, inject } from '@angular/core';
import { ErrorAlertMessageModel, ErrorMessageModel } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';
import { ENV_TOKEN } from '../../configs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private environmet = inject(ENV_TOKEN)


  get isProd(){
    return this.environmet.production
  }

  public getClientMessage(error: Error): ErrorAlertMessageModel {
    if (!navigator.onLine) {
      return {
        content: 'No hay conexión a internet',
        title: 'Error de conexión',
      };
    }
    return {
      content: error.message ? error.message : error.toString(),
      title: error.name ? error.name : 'Error',
    };
  }

  public getClientStack(error: Error): string {
    return error.stack ? error.stack : error.toString();
  }

  public getServerMessage(error: ErrorMessageModel): ErrorAlertMessageModel {
    const msgContent = `
      <p>${error.message}</p>
      <p>${
        error.stack.error && error.stack.error['customMessage']
          ? error.stack.error['customMessage']
          : ''
      }</p>
    `;
    return { content: msgContent, title: 'Error' };
  }

  public getServerStack(error: ErrorMessageModel): HttpErrorResponse {
    return error.stack;
  }
}
