/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class CustomValidations {
  public static passwordValidation(
    password: string,
    confirmPassword: string
  ): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const passwordValue = formGroup.get(password)?.value;
      const confirmPasswordValue = formGroup.get(confirmPassword)?.value;

      if (passwordValue !== confirmPasswordValue) {
        formGroup.get(confirmPassword)?.setErrors({ noMatch: true });
        return { noMatch: true };
      }

      formGroup.get(confirmPassword)?.setErrors(null);

      return null;
    };
  }

  public static emailsValidation(
    email: string,
    confirmEmail: string
  ): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const emailValue = formGroup.get(email)?.value;
      const confirmEmailValue = formGroup.get(confirmEmail)?.value;

      if (emailValue !== confirmEmailValue) {
        formGroup.get(confirmEmail)?.setErrors({ noMatchEmail: true });
        return { noMatchEmail: true };
      }

      formGroup.get(confirmEmail)?.setErrors(null);

      return null;
    };
  }

  public static patternValidator(
    regex: RegExp,
    error: ValidationErrors
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  public static nitGuatemalaValidator(
    control: FormControl
  ): ValidationErrors | null {
    const nit = control.value;
    if (!nit) {
      return {
        errorNitGuat: true,
      };
    }

    let nd:any;
    let add = 0;

    // eslint-disable-next-line no-useless-escape
    if ((nd = /^(\d+)\-?([\dkK])$/.exec(nit))) {
      nd[2] = nd[2].toLowerCase() === 'k' ? 10 : parseInt(nd[2]);
      for (let i = 0; i < nd[1].length; i++) {
        add += ((i - nd[1].length) * -1 + 1) * nd[1][i];
      }
      return (11 - (add % 11)) % 11 === nd[2]
        ? null
        : {
            errorNitGuat: true,
          };
    } else {
      return {
        errorNitGuat: true,
      };
    }
  }

  public static cardTypeValidator(
    control: FormControl
  ): ValidationErrors | null {
    const cardNumber = control.value;
    if(!cardNumber){
      return {
        cardTypeError: true,
      }
    }
    const cleanedNumber = cardNumber.replace(/\D/g, '');
    if (/^4/.test(cleanedNumber)) {
      return null;
    } else if (/^5[1-5]/.test(cleanedNumber)) {
      return null;
    } else if (/^3[47]/.test(cleanedNumber)) {
      return null;
    }

    return {
      cardTypeError: true,
    };
  }
}
