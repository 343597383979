import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { CommonModule } from '@angular/common';
import { currencyFormatter } from '@carwash-project/modules/utils';

function transformPrice(params:string) {
  return currencyFormatter.format(Number(params))
}

@Component({
    selector: 'ui-gift-card',
    templateUrl: './ui-gift-card.component.html',
    styleUrl: './ui-gift-card.component.less',
    standalone: true,
    imports: [UI_NGZORRO, CommonModule],
    changeDetection:ChangeDetectionStrategy.OnPush
})
export class UiGiftCardComponent {
  @Input()
  public description:string = '';

  @Input({ transform: transformPrice})
  public price:string = '';

  @Input({ transform: booleanAttribute })
  public small!: boolean;
}
