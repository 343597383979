import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorHandler } from './error-handler';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class ErrorHandlerModule { 
  public static forRoot(): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
      ]
    };
  }
}
