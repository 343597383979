import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconAlertModel } from '../../models/interfaces/icons-alert.interfaces';

@Component({
  selector: 'ui-icon-alerts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-icon-alerts.component.html',
  styleUrl: './ui-icon-alerts.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiIconAlertsComponent {
  @Input({required:true})
  public type:IconAlertModel = 'check'
}
