/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';

import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import version from 'app-version.json';
import { UI_NGZORRO } from '../../components/ui-ngzorro';
import { LayoutStylesModel, UserUiModel } from '../../models';
import { UiAlertsService } from '../../services';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ui-panel-layout',
  templateUrl: './ui-panel-layout.component.html',
  styleUrl: './ui-panel-layout.component.less',
  standalone: true,
  imports: [UI_NGZORRO, CommonModule],
})
export class UiPanelLayoutComponent implements AfterContentChecked {
  private readonly breakpointObserver: BreakpointObserver =
    inject(BreakpointObserver);
  private readonly ref: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly alertService = inject(UiAlertsService);

  public readonly appVersion: string = version.version;
  public year: number = new Date().getFullYear();
  public isCollapsed: boolean = false;
  public isSmallScream: boolean = false;

  @Input()
  public user: UserUiModel | null = null;

  @Input()
  public enabledFloatButton: boolean = false;

  @Input()
  public configs: LayoutStylesModel | null = null;

  @Output()
  public logoutEvent = new EventEmitter<void>();

  @ContentChild('mainContent')
  public content: TemplateRef<void> | null = null;

  @ContentChild('menu')
  public tplMenu: TemplateRef<void> | null = null;

  @ContentChild('search')
  public tplSearch: TemplateRef<void> | null = null;

  constructor() {
    this.breakpointObserver
      .observe('(max-width: 767px)')
      .subscribe((isMatched) => {
        this.isSmallScream = isMatched.matches;
      });
  }

  public ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  public handlerOpen() {
    this.breakpointObserver
      .observe('(max-width: 992px)')
      .subscribe((isMatched) => {
        this.isCollapsed = isMatched.matches;
      });
  }

  public onLogout() {
    this.logoutEvent.emit();
  }

  public logoutInit() {
    this.isCollapsed = this.isSmallScream;
    this.alertService.create({
      icon: 'question',
      title: 'Cerrar sesión',
      message: '¿Está seguro de cerrar sesión?',
      buttons: [
        {
          type: 'default',
          label: 'Cancelar',
          onClick:(ref:NzModalRef)=>{
            ref.close()
          }
        },
        {
          type: 'primary',
          label: 'Aceptar',
          onClick: (ref:NzModalRef) => {
            this.onLogout();
            ref.close()
          },
        },
      ],
    });
  }
}
