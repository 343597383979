import { Pipe, PipeTransform } from '@angular/core';
import { currencyFormatter } from '../functions';

@Pipe({
  name: 'currencyQ',
  standalone: true,
})
export class CurrencyQPipe implements PipeTransform {
  transform(value: number | undefined | string): unknown {
    if (!value) return value;
    return value
      ? currencyFormatter.format(
          typeof value === 'string' ? Number(value) : value
        )
      : value;
  }
}
