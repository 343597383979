/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UTILS } from '@carwash-project/modules/utils';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { v4 as uid } from 'uuid';
import {
  ColumnsModel,
  TableConfigsModel,
  TagConditionModel,
} from '../../models';
import { TableData } from '../../models/class';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
  selector: 'ui-table',
  templateUrl: './ui-table.component.html',
  styleUrl: './ui-table.component.less',
  standalone: true,
  imports: [UI_NGZORRO, CommonModule, FormsModule, UTILS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableComponent implements OnChanges {
  private readonly breakpointObserver: BreakpointObserver =
    inject(BreakpointObserver);

  public displayData: any[] = [];
  public allChecked: boolean = false;
  public isIndeterminate: boolean = false;
  public isSmallScream: boolean = false;
  public currentPageSize: number = 0;
  public columnsDisplay: ColumnsModel[] = [];
  public columnsHide: ColumnsModel[] = [];

  @Input({ required: true })
  public dataSet: TableData | null = null;

  @Input({ required: true })
  public columns: ColumnsModel[] = [];

  @Input({ required: true })
  public configs: TableConfigsModel | null = null;

  @Input()
  public loading: boolean | null = false;

  @Input()
  public disabledResponsive: boolean = false;

  @Output()
  public pageChangeEvent = new EventEmitter<number>();

  @Output()
  public currentPageDataEvent = new EventEmitter<readonly any[]>();

  @Output()
  public queryParamsEvent = new EventEmitter<NzTableQueryParams>();

  @Output()
  public selectRowEvent = new EventEmitter<any[]>();

  @Output()
  public checkSelectEvent = new EventEmitter<boolean>();

  @Output()
  public clickLinkEvent = new EventEmitter<any>();

  @Output()
  public switchRowEvent = new EventEmitter<{ status: boolean; row: any }>();

  @Output()
  public sortServerEvent = new EventEmitter<{
    event: string | null;
    column: ColumnsModel;
  }>();

  @ContentChild('actions')
  public actions: TemplateRef<any> | null = null;

  @ContentChild('cellTemplate')
  public customCell: TemplateRef<any> | null = null;

  @ContentChild('rightHeader')
  public additionalHeader: TemplateRef<any> | null = null;

  constructor() {
    this.breakpointObserver
      .observe('(max-width: 767px)')
      .subscribe(({ matches }) => {
        this.isSmallScream = matches && !this.disabledResponsive;
        this.setColumns(this.columns, this.isSmallScream);
      });
  }

  public ngOnChanges(): void {
    if (this.dataSet && this.dataSet.results) {
      this.displayData = ([] as any[]).concat(
        this.dataSet.results.map((item) => ({
          ...item,
          expand: false,
          uid: uid(),
        }))
      );
    }

    this.setColumns(this.columns, this.isSmallScream);
  }

  public handleClickLink(row: any) {
    this.clickLinkEvent.emit(row);
  }

  public handelPageIndexChange(event: number) {
    this.pageChangeEvent.emit(event);
  }

  public handleCurrentPageDataChange(event: readonly any[]) {
    this.currentPageDataEvent.emit(event);
    this.currentPageSize = event.length;
  }

  public handleQueryParams(event: NzTableQueryParams) {
    this.queryParamsEvent.emit(event);
  }

  public onChecked(event: boolean) {
    this.checkSelectEvent.emit(event);
  }

  public refreshStatus(row: any = null): void {
    const validData = this.displayData.filter((value) => !value.disabled);

    if (this.configs?.canMultiSelection) {
      const allChecked =
        validData.length > 0 &&
        validData.every((value) => value.checked === true);
      const allUnChecked = validData.every((value) => !value.checked);
      this.allChecked = allChecked;
      this.isIndeterminate = !allChecked && !allUnChecked;
    } else {
      this.displayData = this.displayData.map((item) => ({
        ...item,
        checked: item.uid === row.uid ? item.checked : false,
      }));
    }

    const checkList = this.displayData.filter((value) => value.checked);
    this.selectRowEvent.emit(checkList);
  }

  public onCheckAll(value: boolean): void {
    this.displayData.forEach((data) => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  public handleSwitch(event: boolean, row: any) {
    this.switchRowEvent.emit({
      status: event,
      row,
    });
  }

  public setColumns(columns: ColumnsModel[], isResponsive: boolean) {
    if (isResponsive && columns.length) {
      const { columnsDisplay, columnsHide } = this.orderColumns(columns);
      this.columnsDisplay = columnsDisplay;
      this.columnsHide = columnsHide;
    } else if (columns.length) {
      this.columnsDisplay = columns;
      if (this.dataSet) {
        this.displayData = ([] as any[]).concat(
          this.dataSet.results.map((item) => ({
            ...item,
            expand: false,
            uid: uid(),
          }))
        );
      }
    }
  }

  public orderColumns(columns: ColumnsModel[]) {
    const columnsList = [...columns];
    let columnsDisplay: ColumnsModel[] = [],
      columnsHide: ColumnsModel[] = [];
    const colPriority = columnsList.filter((col) => col.priority),
      colNotPriority = columnsList.filter((col) => !col.priority);
    if (colPriority.length) {
      if (colPriority.length === 1) {
        columnsDisplay = columnsDisplay.concat(colPriority[0]);
        columnsHide = columnsHide.concat(colNotPriority);
      } else {
        const [firstCol, ...hidenCol] = colPriority.sort(
          (a, b) => a.priority! - b.priority!
        );
        columnsDisplay = columnsDisplay.concat(firstCol);
        columnsHide = columnsHide.concat(hidenCol);
        columnsHide = columnsHide.concat(colNotPriority);
      }

      return {
        columnsDisplay,
        columnsHide,
      };
    }

    const [firstCol, ...hidenCol] = columnsList;

    return {
      columnsDisplay: columnsDisplay.concat(firstCol),
      columnsHide: columnsHide.concat(hidenCol),
    };
  }

  public setColorTag(rowValue: any, conditions: TagConditionModel[]): string {
    const color = conditions.find((i) => i.value == rowValue)?.color;
    return color ? color : '';
  }

  public onSortChange(event: string | null, column: ColumnsModel) {
    this.sortServerEvent.emit({
      event,
      column,
    });
  }
}
