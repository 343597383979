<!-- <nz-modal
  nzClassName="alert-custom-modal"
  [nzVisible]="uiAlertsService.getDisplayModal()"
  (nzVisibleChange)="onVisibleChange()"
  [nzWidth]="380"
  [nzCentered]="true"
  [nzMaskClosable]="false"
  [nzCloseIcon]="CustomIcon"
  [nzContent]="modalContent"
  [nzFooter]="alertConfig && alertConfig.buttons ? modalFooter : null"
  (nzOnCancel)="onCancel()"
/> -->

<ng-template #customIcon>
  <i nz-icon nzType="close-circle" nzTheme="fill" class="close-icon"></i>
</ng-template>

<ng-template #modalContent>
  <nz-space nzDirection="vertical" [nzSize]="8">
    <div *nzSpaceItem nz-row nzJustify="center" nzAlign="middle">
      @if (alertConfig && alertConfig.icon !== 'none') {
      <ui-icon-alerts [type]="alertConfig.icon" />
      }
    </div>

    <div *nzSpaceItem nz-row nzJustify="center" nzAlign="middle">
      <span class="title-alerts">{{alertConfig && alertConfig.title }}</span>
    </div>

    <div *nzSpaceItem nz-row nzJustify="center" nzAlign="middle">
      <span class="subtitle-alerts" [innerHTML]="alertConfig && alertConfig.message"></span>
    </div>
  </nz-space>
</ng-template>

<ng-template #modalFooter let-ref="modalRef">
  <div nz-row [nzGutter]="[8, 16]">
    @for (item of alertConfig?.buttons; track $index) {
    <div nz-col [nzSpan]="24 / alertConfig?.buttons!.length">
      <button
        nz-button
        [nzType]="item.type"
        [ngStyle]="{
          width: alertConfig?.buttons!.length === 1 ? '60%' : '100%'
        }"
        (click)="item.onClick ? item.onClick(ref) : null"
      >
        @if (item.icon) {
        <span nz-icon [nzType]="item.icon"></span>
        }
        {{ item.label }}
      </button>
    </div>
    }
  </div>
</ng-template>
