import { ChangeDetectionStrategy, Component, EventEmitter, Output, computed, forwardRef, signal } from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { UTILS } from '@carwash-project/modules/utils';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
    selector: 'ui-input-value',
    templateUrl: './ui-input-value.component.html',
    styleUrl: './ui-input-value.component.less',
    standalone: true,
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UiInputValueComponent),
        multi: true,
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => UiInputValueComponent),
        multi: true,
      },
      provideNgxMask()
    ],
    imports: [
      UI_NGZORRO,
      UTILS,
      NgxMaskDirective, 
      NgxMaskPipe
    ],
    changeDetection:ChangeDetectionStrategy.OnPush,
})
export class UiInputValueComponent implements ControlValueAccessor, Validator {
  private onChanged!: (value: number) => void;
  private onTouch!: () => void;

  public isDisabled = false;
  public value = 0;
  public typeSelected = signal<string>('Porcentual')
  public suffix = computed(()=>{
    return this.typeSelected()==='Porcentual' ? ' %':''
  })

  public prefix = computed(()=>{
    return this.typeSelected()==='Fijo' ? 'Q.':''
  })

  public mask = computed(()=>{
    return this.typeSelected()==='Porcentual'?'000':'separator.2'
  })


  @Output()
  public selectTypeEvent = new EventEmitter<string>();


  public onChangeValue(value:number){
    this.onChanged(value)
  }

  public handleSelectOption(option:string){
    this.typeSelected.set(option)
    this.selectTypeEvent.emit(option)
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return control.hasValidator(Validators.required) && !this.value
    ? { isRequired: true }
    : null;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.onChanged = fn;
  }

  public writeValue(value: number): void {
    this.value = value;
  }

  public registerOnChange(fn: ()=>void): void {
    this.onChanged = fn
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
   this.isDisabled = isDisabled;
  }
}
